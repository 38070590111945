html,
body {
  display: block;
  margin: 0;
  width: 100%;
  height: 100vh;
}

body {
  position: relative;
  font-family: var(--font-family) !important;
  font-size: var(--font-size);
}

a {
  text-decoration: none;
}

*,
::before,
::after {
  box-sizing: border-box;
}
