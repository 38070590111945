/* ====================  My-Profile Properties ================*/
/* =========================================================== */
.my-profile-card {
  width: 100%;
  height: 160px;
  background: url('assets/img/page-header-bg.png');
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-repeat: round;
}
@media (max-width: 425px) {
  .my-profile-card {
    display: none;
  }
  .my-certificates-card {
    padding: 0;
  }
  .signature-box {
    margin: 12px 0 12px 0;
  }
  .box-title {
    font-size: 12px;

  }
  .change-password-text {
    font-size: 12px !important;
  }
  .my-personal-informations-title {
    font-size: 18px !important;
  }
  .see-all-text {
    font-size: 12px !important;
  }
}

.transaction-history {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.03em;
  color: #33363d;
}
.transaction-history-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #555a65;
  margin-top: 8px;
}
.personal-information {
  display: flex;
  margin-top: 36px;
}
@media (max-width: 425px) {
  .personal-information {
    display: grid;
    margin-top: 12px;
  }
  .my-personal-informations-card {
    margin-right: 0;
    padding: 0;
  }
  .personal-information-text {
    padding: 12px 0;
  }
  .box-title {
    font-size: 12px !important;
    text-align: center;
  }
}

.my-personal-informations-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
  white-space: nowrap;
}
.change-password-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0D6EFD;
  cursor: pointer;
  white-space: nowrap;
}
.personal-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #747881;
}
.personal-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #33363d;
  margin-top: 6px;
}
.two-factor-authentication-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
  margin-top: 6px;
}
.radio-button-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #33363d;
  margin-right: 24px;
  margin-left: 8px;
}

.text-area {
  background: #fbfbfb;
  border: 1px solid #d1d2d6;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color:   #0d6efd;;
  padding: 10px;
}

.my-personal-informations-card {
  height: auto;
  border: 1px solid #d1d2d6;
  border-radius: 6px;
  margin-top: 20px;
  margin-right: 24px;
}

.my-certificates-card {
  height: auto;
  border: 1px solid #d1d2d6;
  border-radius: 6px;
  margin-top: 20px;
}
@media (min-width: 1200.98px) {
  .my-certificates-card {
    width: 445px;
  }
}
.transaction-history-card {
  height: auto;
  border: 1px solid #d1d2d6;
  border-radius: 6px;
  margin-top: 20px;
}
.see-all-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #747881;
  cursor: pointer;
}
.valid-signature-page {
  padding: 36px 64px;
  gap: 24px;
  width: 696px;
  height: 582px;
  background: #ffffff;
  border: 1px solid #d1d2d6;
  border-radius: 6px;
}
.valid-signature-header {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  justify-content: center;
  letter-spacing: -0.03em;
  color: #33363d;
}
.sign-detail-container {
  margin-top: 24px;
  background-color: #fff;
  color: #545454;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.signature-detail-title {
  margin-bottom: 24px !important;
}
.signature-box {
  margin-top: 24px;
  width: -webkit-fill-available;
  height: 100px;
  background: #ffffff;
  border: 1px solid #d1d2d6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.box-title {
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #747881;
}
.box-label-1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
  margin-top: 6px;
}
.box-label-2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #93969d;
  margin-top: 6px;
}
.box-label-3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #20c997;
  margin-top: 6px;
}
.box-label-4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #dd3838;
  margin-top: 6px;
}
.process-history-datatable {
  margin: 24px 0;
  padding: 1px 1px 0 1px;
}

/* ====================  Modal Properties ==================== */
/* =========================================================== */

.modal-header-time {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #000000;
  padding: 22px 0 22px 28px !important;
  border-bottom: 1px solid #f0f0f2;
}

.time-stamp-modal {
  height: 100% !important;
}
.process-modal-content {
  padding: 16px 28px;
}
.process-detail-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #93969d;
  margin-bottom: 8px;
}
.process-detail-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
}
.process-detail-text-success {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
  padding: 6px 12px;
  gap: 10px;
  height: 28px;
  background: #afe3be;
  border-radius: 4px;
  max-width: 100px;
}
.process-detail {
  margin-bottom: 32px;
}
.process-details {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
}
.process-description-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
  margin-bottom: 26px;
}
