.main-container {
  margin: 20px;
}

.dashboard-logo {
  height: 96px;
  margin-bottom: 8px;
}

.text-muted-mt {
  margin-top: 10px;
}

.header-logout-icon {
  margin-top: 4px;
}
.logout {
  cursor: pointer;
}
.lang-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #555a65;
  margin-top: 5px;
}

.sb-nav-fixed #layout-sidenav #layout-sidenav_content {
  transition: 500ms ease;
  overflow-x: auto;
}

.sb-topnav {
  min-height: auto !important;
  border-bottom: solid 1px #d1d2d6;
  background-color: #fff !important;
  padding: 16px 20px !important;
}
.header {
  height: 73px;
  background: #fff;
  border-bottom: 1px solid #d1d2d6;
  padding: 16px 20px;
  justify-content: center !important;
}
.header-text {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: -0.03em !important;
  color: #555a65 !important;
}
.header-text-content {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #93969d;
  margin-top: 2px;
}
.header-mobile {
  width: auto;
  padding: 14px 16px;
  background: url('assets/img/Mobile-Top-Bar.png');
  display: flex;
  background-repeat: round;
  justify-content: space-between;
  align-items: center;
}
.header-mobile-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
}
.settings-svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.hamburger-menu-svg {
  width: 24px;
  height: 24px;
}
.avatar {
  width: 40px !important;
  height: 40px !important;
  background: #0d6efd !important;
  border-radius: 40px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-left: 10px !important;
  cursor: pointer !important;
}
.sidenav-menu-border {
  border-radius: 0px;
  border-right: 1px solid #0d6efd;
}

body {
  background-color: #eff3f7;
}

.welcome-logo {
  height: 100px;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
  color: #747881 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
}

.sb-sidenav-menu-logo {
  margin: 24px 0;
}
.nav-link {
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #444851 !important;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.nav-link-parent {
  margin: 0 0.1rem !important;
}
.nav-link-close {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #444851;
}
.parent-menu {
  border-radius: 6px;
  width: 100%;
  align-items: center;
  display: flex;
  margin: 4px 0px;
  padding: 0.5rem 0.5rem;
}
.parent-menu:hover {
  background: #dadafc;
  border-radius: 6px;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0.5rem 0.5rem;
}
.route-link {
  border-radius: 6px;
  width: 100%;
  align-items: center;
  display: flex;
  white-space: nowrap;
  padding: 8px;
  cursor: pointer;
}
.svg-route-parent {
  padding-left: 2px;
  padding-top: 3px;
  margin-right: 4px;
  width: 24px;
  height: 24px;
  color: #0d6efd;
}
.svg-route-parent-close {
  padding-left: 2px;
  padding-top: 3px;

  width: 24px;
  height: 24px;
  color: #0d6efd;
}
.menu-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
}
.home-text {
  white-space: nowrap;
}
.route-link-close {
  border-radius: 6px;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0.5rem 0.5rem;
  padding: 8px;
}
.route-link-close:hover {
  background: #cfe2ff;
  border-radius: 6px;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 0.5rem 0.5rem;
}
.route-link:hover {
  background: #cfe2ff;
  border-radius: 6px;
  width: 100%;
  align-items: center;
  display: flex;
}
.active .route-link {
  background: #0d6efd;
  color: white !important;
  width: 100%;
  align-items: center;
  display: flex;
  border-radius: 6px;
  padding: 8px;
}
.active .route-link-close {
  background: #0d6efd;
  color: white !important;
  width: 100%;
  align-items: center;
  display: flex;
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
}
.svg-route {
  padding-top: 3px;
  padding-left: 2px;
  width: 24px;
  height: 24px;
  color: #0d6efd;
  filter: brightness(0) saturate(100%) invert(29%) sepia(46%) saturate(3945%) hue-rotate(208deg) brightness(100%) contrast(100%);
}
.active .svg-route {
  color: white !important;
  filter: brightness(0) invert(1);
}

.svg-logo {
  width: 10.75rem;
  height: 2.625rem;
}

.nav-link-child {
  padding: 0.25rem 0.25rem;

  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;

  color: #555a65 !important;
}

.nav-link-child-close {
  padding: 0.25rem 0.25rem;
  margin-left: 8px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;

  color: #555a65 !important;
}
.child-text {
  margin-left: 5px;
}
.active .child-text {
  color: #0d6efd!important;
}
.copyright {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #93969d;
}
.svg-child {
  padding-top: 3px;
  width: 24px;
  height: 24px;
}
.svg-use-child {
  color: #c3c6cb;
  background: #e1e3e5;
  border: 1.5px solid #c3c6cb;
}
.active .svg-use-child {
  color: #6ea8fe;
}
.line {
  height: 32px;
  width: 1px;
  background: #e1e3e5;
  position: absolute;
  margin-left: 4px;
  display: flex;
  margin-top: 6px;
  margin-top: -8px;
}
.sb-sidenav-menu-close {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  margin-left: 237px;
  position: absolute;
  color: #0d6efd;
}
.sb-sidenav-menu-open {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 50px;
  position: absolute;
  color: #0d6efd;
}
.svg-open-close {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.sidenav-menu-close {
  width: 86px;
  height: 100%;
  transition: 500ms ease !important;
}
.sidenav-menu {
  transition: 500ms ease !important;
}

@media (max-width: 425px) {
  .sidenav-menu {
    position: absolute;
    z-index: 1;
    background: white;
    margin-top: -56px;
  }
}
@media (min-width: 426px) {
  .settings-dialog {
    width: 270px !important;
    margin-top: 65px !important;
    height: 200px !important;
  }
}

@media (max-width: 425px) {
  .sidenav-menu-close {
    display: none;
  }
  .settings-dialog {
    margin-top: 50px !important;
    width: 240px !important;
    height: 200px !important;
  }
}
/* sweetalert custom css */

.swal2-popup.swal2-toast .swal2-close {
  margin-bottom: 45px !important;
}

.swal2-popup.swal2-toast .swal2-success {
  border: none;
}

.swal2-icon.swal2-warning {
  border: none !important;
}
.swal2-icon {
  margin: 24px auto 0 !important;
  height: auto !important;
}

.swal2-icon.swal2-error {
  border: none !important;
}

.swal2-icon.swal2-info {
  border: none !important;
}

/* sweetalert custom css */
.search-box-input {
  margin-left: 24px !important;
  width: 400px !important;
  height: 38px !important;
  padding-left: 38px !important;
}

.search-box {
  border-radius: 10px;
  background-color: #fff;
  margin-left: 24px !important;
  position: fixed;
  width: 400px;
  margin-top: 3px;
}

.search-list-content {
  margin: 10px;
}

.search-list {
  list-style: none;
  padding-left: 0rem;
}

.search-list-item {
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
}

.search-list-item:hover {
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
  background-color: #ecf4ff;
}

.active .search-list-item {
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
  background-color: #dadafc;
}

.search-list-text {
  color: #000000;
  text-decoration: none;
}

.search-icon {
  position: absolute;
}

.search-svg {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 5px;
  left: 36px;
}

.search-close-icon {
  position: absolute;
  left: 356px;
}
.search-warning {
  margin-top: 4px;
  padding: 8px;
}

.pi {
  font-size: 0.85rem !important;
}

.dropdown-svg {
  margin-right: 10px;
}
/* Popup */
.modal-width {
  padding: 0 !important;
  height: auto;
  width: 360px !important;
}
.modal-container {
  margin: 24px 32px !important;
  padding: 0 !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: -0.03em !important;
  color: #444851 !important;
  overflow: inherit !important;
}
.modal-title {
  padding: 0;
  margin: 64px 72px 12px 72px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 28px !important;
  line-height: 34px !important;
  display: flex !important;
  letter-spacing: -0.03em !important;
  color: #33363d !important;
  justify-content: center !important;
}
.modal-actions {
  margin: 16px 32px !important;
  display: flex !important;
  align-items: flex-start !important;
  padding: 0px !important;
  gap: 10px !important;
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
  flex-wrap: inherit !important;
}
.swal2-styled.swal2-confirm {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 12px !important;
  gap: 4px !important;
  height: 36px !important;
  background: #0d6efd!important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #ffffff;
  width: 143px;
}

.swal2-styled.swal2-cancel {
  display: inline-block !important;
  border: 1px solid #3b82f6 !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  width: 143px !important;
  height: 36px !important;
  color: #3b82f6 !important;
  background-color: #fff !important;
}
#swal2-html-container {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: -0.03em !important;
}

.page-header {
  background: url(../img/page-header-bg.png) center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  border-radius: 5px;
  font-weight: 600;
  color: #33363d;
}

.page-header p {
  font-weight: 400;
  color: #555a65;
  font-size: 14px;
}

.cert-item {
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  color: #545454;
  cursor: pointer;
  -webkit-box-shadow: inset 0 0 0 1px #e0e0e0;
  -moz-box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 0;
  box-shadow: inset 0 0 0 1px #e0e0e0 !important;
  max-height: 100px;
  width: 100%;
  text-align: left;
  overflow: hidden;
  border-color: transparent;
  border: 0;
  gap: 1rem;
  position: relative;
}

.certDetailButton {
  background: none;
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.cert-item:hover {
  background-color: #f9f9f9;
}

.cert-item.selected {
  outline: 8px solid #75acfe;
}

.cert-item .cert-status {
  width: 27px;
  min-width: 27px;
  padding-top: 100%;
  display: flex;
  border-radius: 3px 0 0 3px;
  position: relative;
}

.cert-item .invalid.cert-status {
  background: #f47575;
  border: 1px solid #c86c6c;
  border-right: 0;
  min-width: 21px;
}

.cert-item .valid.cert-status {
  background: #7fbd75;
  border: 1px solid #73a06c;
  border-right: 0;
  min-width: 21px;
}

.cert-item .valid.cert-status span {
  margin-top: 0;
}

.cert-item .cert-status span {
  transform: rotate(-90deg);
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
}

.cert-item .cert-select-body .cert-rsa {
  color: #93969d;
  font-size: 12px;
}

.cert-issuer {
  color: #747881;
}

@media (max-width: 425.98px) {
  .left-sidebar {
    display: none;
  }
}
.mobile-logo-hamburger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
/* Settings Modal css özellikleri */
.settings-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #b2b4ba;
}
.account-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #222428;
  margin-top: 12px;
}
.account-mail {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #93969d;
  margin: 2px 0 12px 0;
}
.account-sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 0px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #93969d;
  margin: 0px 0px 24px 0px;
}
.sign-out-svg {
  width: 24px;
  height: 20px;
}
.sign-out {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  letter-spacing: -0.03em;
  color: #555a65;
}
.p-button-secondary:hover {
  padding: 8px;
  background: #f0f0f2 !important;
}
.p-button {
  padding: 0.75rem 0.25rem !important;
}

.sign-out-content:hover {
  background: #f0f0f2;
  border-radius: 4px;
  padding: 8px;
}
.active .sign-out-content {
  background: #f0f0f2;
  border-radius: 4px;
  padding: 8px;
}
.language-option {
  width: auto;
  height: 40px;
  background: #e9e9ff;
  border-radius: 6px;
  margin-top: 12px;
  padding: 4px;
}
.change-language {
  margin-top: 12px;
  height: 40px;
  display: flex;
  align-items: center;
}
.modal-title {
  display: none !important;
}
.checked {
  left: 0 !important;
  position: absolute;
  margin: 2px 0 0 15px;
}

.checked-icon {
  position: absolute;
  right: 0;
  margin-top: 5px;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #0d6efd !important;
  background: #0d6efd !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{

  background: #EEF2FF !important;;
  border-color: #EEF2FF !important;;
  color: #0d6efd !important;;
}
.p-button{
  color: #ffffff;
  background: #0d6efd !important;
  border: 1px solid #0d6efd !important;
}
.mobilSideMenuClose {
  left: -274px;
  transition: 500ms ease !important;
}
.mobilSideMenuOpen {
  left: 0;
  transition: 500ms ease !important;
}
