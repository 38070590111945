.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 12px;
  padding-top: 10px;
}
.p-datatable-header {
  background-color: #f8fafc !important;
}
.transaction-history-text {
  font-size: 20px;
}
.transaction-history-header {
  padding: 24px 24px 0px 24px;
}
.table-content {
  margin: 24px 24px 32px 24px;
}
.transaction-history-content {
  border: 1px solid #d1d2d6;
  border-radius: 6px;
}
.log-level-warn {
  color: #ffa500;
  font-weight: 700;
  font-size: 16px;
}
.modal-header-time {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #000000;
  padding-bottom: 8px;
}

.time-stamp-modal {
  height: 100% !important;
}

.process-modal-content {
  padding: 16px 28px;
}
.process-detail-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #93969d;
  margin-bottom: 8px;
}
.process-detail-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
}
.process-detail-text-success {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  gap: 10px;
  height: 28px;
  border-radius: 4px;
  max-width: 100px;
}
.process-detail {
  margin-bottom: 32px;
}
.process-details {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
}
.process-description-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #33363d;
  margin-bottom: 10px;
}
.detail-opacity {
  opacity: 0.6;
}
.transaction-date-svg {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 10px;
  right: 72px;
  z-index: 9;
  cursor: pointer;
}
.transactionHistoryCalendar {
  width: 400px;
}
.transactionHistorySearch {
  width: 400px;
}
.calender-h {
  height: 42px;
}
