/* ================ Prime Card Overrides Start ================= */
/* =========================================================== */

.p-card-content {
  padding: 0 !important;
}

.p-card-body {
  padding: 24px !important;
}
@media (max-width: 425px) {
  .p-card-body {
    padding: 16px 16px 4px 16px !important;
  }
}
.p-card {
  box-shadow: none !important;
}

/* ================ Prime Card Overrides End ================= */
/* =========================================================== */

/* ================ Prime DataTable Overrides Start /=================== */
/* =========================================================== */

.p-datatable .p-datatable-tbody > tr > td {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #444851;
}
@media (max-width: 425px) {
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 12px;
  }
}
.p-datatable {
  height: 100%;
  border: 1px solid #d1d2d6;
  border-radius: 6px;
  background-color: #fff;
}
.p-datatable-customers {
  margin-top: 24px;
}
.p-column-title {
  font-weight: 600 !important;
}

.p-datatable .p-datatable-header {
  border-radius: 4px !important;
}
.p-datatable .p-paginator-bottom {
  border-radius: 4px !important;
}
/* ================ Prime DataTable Overrides End/=================== */
/* =========================================================== */

/* ================ Prime Modal Overrides Start =================== */
/* =========================================================== */
.p-dialog-header-close-custom {
  margin: 8px 5px 0 !important;
  height: 24px !important;
  width: 24px !important;
}
.p-dialog-header-custom {
  padding: 0 !important;
  position: absolute;
  right: 0;
  margin: 12px 14px !important;
  z-index: 999999;
}
.p-dialog-content-custom {
  padding: 0 !important;
  border-radius: 4px 4px !important;
}
.p-dialog-header-icons-avatar-custom {
  margin-right: 12px !important;
  margin-top: 12px !important;
}
.p-dialog-header-icons-custom {
  margin-right: 24px !important;
  margin-top: 28px !important;
}
.p-dialog-content {
  padding: 0 0 0 0 !important;
}
#pr_id_2 {
  /* Modalın yüksekliği auto olarak ayarlandı.  */
  height: auto !important;
}
/* Settings modalının ayarlamaları */
.p-dialog-header-icon {
  margin-top: -8px !important;
  margin-right: -8px !important;
}

/* ================ Prime Modal Overrides End =================== */
/* =========================================================== */
/* ================  Button Ayarları ========================== */
.p-button.p-button-text.p-button-plain {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  width: 50%;
}
.p-button-label:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #0d6efd;
  width: 50%;
}
.p-button-label:active {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #0d6efd;
  width: 50%;
}
.p-button-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #0d6efd;
  width: 50%;
  margin: 4px;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  padding: 8px;
  background: #ffffff !important;
  border-radius: 4px;
  width: 50%;
}

.p-button.p-button-text.p-button-plain:enabled:active {
  padding: 8px;
  background: #ffffff !important;
  border-radius: 4px;
  width: 50%;
}
/* Dropdown ayarları */
.p-inputtext {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  letter-spacing: -0.03em;
  color: #555a65;
}
