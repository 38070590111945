/* stylelint-disable at-rule-empty-line-before */

/* stylelint-disable function-url-quotes */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./Inter-Thin.ttf) format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./Inter-Black.ttf) format("truetype");
}
